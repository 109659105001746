<template>
  <div class="zrjs_box" ref="zrjs_box">
    <h1>{{data.h1}}</h1>
    <nav>
      <p>{{ data.Departmen }}</p>
      <nameSeach :poId="poId_" @py_Change="py_Change_" :en="true"/>
        <!-- 老师区域 -->
      <div class="nama_tach">
        <span>Sort by first letter of last name:</span>
        <div class="card_name">
          <nav v-for="(item,i) in data.thach_list" :key="i" @click="push_articleTime(item)">
            <img :src="item.thumb?item.thumb.indexOf('http')!=-1?item.thumb:data.baseUrl+item.thumb:'https://s1.ax1x.com/2022/08/16/vw6g10.png'">
            <section>
               <p>{{item.title_en?item.title_en:'暂无'}}</p>
               <div v-for="itemTwo in item.tags_en&&item.tags_en.slice(0,4)" :key="itemTwo.id">{{itemTwo}}</div>
               <footer>
                <span>more</span>
                <img src="@/assets/zrjsicon.png" alt="">
               </footer>
            </section>
          </nav>
        </div>
      </div>
      <!-- 底部分页 -->
      <div class="pagination">
        <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" :en="true"/>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { get_article_list,getHttp } from '@/api/API.js'
import nameSeach from '@/components/name_seach.vue'
import Pagination from '@/components/Pagination.vue'
import { ElNotification  } from 'element-plus'
import { useRouter,useRoute, } from 'vue-router'
import {ref,onMounted,reactive,watch,onUnmounted} from 'vue'
import miiter from '@/utils/miitbus.js'

const route = useRoute()
const router = useRouter()

const data = reactive({
  thach_list:[],
  baseUrl:process.env.VUE_APP_BASE_URL + '/',
  Departmen:'',
  h1:''
})

const from = reactive({
  page:1,
  limit:10,
  total:0,
  is_en:1,
  search_py:'',
  type_id:route.params.id
})

// 根据路由传的id获取文章列表
let get_Navaeticlexq = async (obj) => {
  const {data:res} = await get_article_list(obj)
  if(res.code == 200){
    // getDepartment()
    data.h1 = res.data.type_name
    data.thach_list = res.data.data
    from.total = res.data.total
  }
}

get_Navaeticlexq(from)
onMounted(() => { })

// 通过分页子组件的自定义事件来改变page
const pageChanges = (e) => {
  from.page = e
  getSearchpy()
} 

const py_Change_ = async (e) => {
  from.search_py = e
  from.page = 1
  getSearchpy()
}
// 拼音关键字获取文章列表
const getSearchpy = async () => {
  const { data: res } = await getHttp('/api/article', from)
  if(res.code == 200){
    data.thach_list = res.data.data
    from.total = res.data.total
  }
}

const push_articleTime = (row) => {
  if(row.jump_url){
    window.open(row.jump_url)
    return
  }else{
    ElNotification({
    title: '警告',
    message: '该教师没有链接。',
    type: 'warning',
    zIndex:999999
  })
  }
}

// 获取系介绍
const getDepartment = async () => {
  const { data: res } = await getHttp('/api/getTypeDesc', {  type_id: routeData.article_id})
  if(res.code == 200){
    data.Departmen = res.data.description
  }
}

miiter.on('onEnactilcetap',() => {
    setTimeout(() => {
      from.type_id = Number(route.params.id)
      from.page = 1
      get_Navaeticlexq(from)
    })
})

</script>

<style lang="less" scoped>
@maxw:128rem;
.zrjs_box{
  width: 100%;
  font-size: 15/@maxw;
  transition: ease .5s;
  >h1{
    font-size: 39/@maxw;
    font-family: Georgia;
    font-weight: bolder;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 10/@maxw 0;
    text-align: center;
  }
  >nav{
    width: 100%;
    >P{
      margin: 40/@maxw 0;
      font-size: 24/@maxw;
      font-family: Source Han Sans CN-Medium;
      font-weight: 500;
      color: #5A5A5A;
    }
    .nama_tach{
      width: 100%;
      margin: 30/@maxw 0 0 0;
      >span{
        color: rgb(183,163,147);
        font-size: 20/@maxw;
        font-family: Source Han Sans CN-Medium;
      }
      .card_name{
        margin: 10/@maxw 0 0 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-family: "Microsoft YaHei";
        >nav{
          display: flex;
          min-width: 525/@maxw;
          height: 220/@maxw;
          background-color: #fff;
          margin: 0 0 36/@maxw 0;
          transition: ease .3s;
          cursor: pointer;
          >img{
            width: 201/@maxw;
            height: 100%;
            vertical-align: top;
          }
          >section{
            position: relative;
            box-sizing: border-box;
            width: 390/@maxw;
            height: 100%;
            background-color: #e8e8e8;
            margin: 0 0 0 15/@maxw;
            padding: 0 0 0 20/@maxw;
            color: #5A5A5A;
            >p{
              font-size: 18/@maxw;
              padding: 8/@maxw 0;
              margin: 0;
              color: #3e7ac2;
              border-bottom: 2/@maxw solid rgb(183,163,147);
            }
            div{
              padding-right: 10/@maxw;
            }
            >div:nth-of-type(1){
              margin-top: 25/@maxw;
            }
            >footer{
              position: absolute;
              bottom: 10/@maxw;
              right: 16/@maxw;
              height: 37/@maxw;
              float: right;
              display: flex;
              font-size: 13/@maxw;
              justify-content: center;
              align-items: center;
              color: #B19A86;
              >img{
                margin-left: 7/@maxw;
                vertical-align: middle;
              }
            }
          }
        }
        >nav:nth-child(2n){
          margin-left: 50/@maxw;
        }
        >nav:hover {
          box-shadow: 0 0 20/@maxw rgba(90, 90, 90, 0.291);
          footer{
              text-decoration: underline;
          }
        }
      }
      
    }
  }
  .pagination{  
  display: flex;
  justify-content: center;
  margin: 13% 0 0 0; 
  }

}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .zrjs_box{
    >h1{
      font-size: 35/@maxw_ !important;
    }
    >nav{
      >p{
        font-size: 28/@maxw_ !important;
      }
    }
    .nama_tach{
      >span{
        font-size: 30/@maxw_ !important;
      }
      .card_name{
        nav{
          width: 800/@maxw_ !important;
          // height: 165/@maxw_ !important;
          height: 36vw !important;
          margin: 0 0 15/@maxw_ 0 !important;
          >img{
            width: 250/@maxw_ !important;
            height: 270/@maxw_ !important;
          }
          >section{
            flex: 1;
            height: 270/@maxw_ !important;
            >p{
              font-size: 30/@maxw_ !important;  
            }
            >footer{
              width: 173/@maxw_ !important;
              height: 47/@maxw_ !important;
              bottom: 21/@maxw_ !important;
              font-size: 25/@maxw_ !important;
            }
            >div:nth-of-type(1){
              margin-top: 30/@maxw_ !important;
              font-size: 25/@maxw_ !important;
            }
            >div:nth-of-type(2){
              font-size: 25/@maxw_ !important;
            }
          }
        }
      }
    }
  }
}
</style>